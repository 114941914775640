<template>
  <!-- Hero -->
  <div class="bg-primary position-relative">
    <div class="container-fluid pt-7 px-lg-5 px-md-5">
      <div class="row text-center align-items-md-center mb-4 p-5">
        <div class="col-sm-12 col-md-12 col-lg-6 mb-7 mb-md-0 text-start">
          <!-- Heading -->
          <div class="px-lg-4 mb-7">
            <h1 class="display-6 text-white mb-3 mb-md-4 fw-bold">
              LIMU Research & Consultation
              <span class="text-info" id="typed"></span>
            </h1>
            <p class="lead text-white-gray fw-600 fs-5">
              LIMU Research Portal facilitates the process of research
              management and administration. The portal assists LIMU affiliated
              researchers/ users to submit and track the status of their
              research proposal applications, from obtaining ethical approval to
              managing the research funds.
            </p>
          </div>
          <!-- End Heading -->

          <div class="d-grid d-sm-flex gap-3 p-lg-5 p-md-5">
            <button
              class="btn btn-primary-dark me-2 p-2 d-flex justify-content-center fw-bold"
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#signUpModal"
            >
              Start now
            </button>
            <router-link
              :to="{ name: 'tutorials' }"
              :key="$router.path"
              class="btn btn-primary me-2 p-2 px-4 fw-bold"
              href="https://limu.edu.ly/"
            >
              Watch tutorials
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 6.30005C20.5 5.30005 19.9 5.19998 18.7 5.09998C17.5 4.99998 14.5 5 11.9 5C9.29999 5 6.29998 4.99998 5.09998 5.09998C3.89998 5.19998 3.29999 5.30005 2.79999 6.30005C2.19999 7.30005 2 8.90002 2 11.9C2 14.8 2.29999 16.5 2.79999 17.5C3.29999 18.5 3.89998 18.6001 5.09998 18.7001C6.29998 18.8001 9.29999 18.8 11.9 18.8C14.5 18.8 17.5 18.8001 18.7 18.7001C19.9 18.6001 20.5 18.4 21 17.5C21.6 16.5 21.8 14.9 21.8 11.9C21.8 9.00002 21.5 7.30005 21 6.30005ZM9.89999 15.7001V8.20007L14.5 11C15.3 11.5 15.3 12.5 14.5 13L9.89999 15.7001Z"
                  fill="#ffff"
                ></path>
              </svg>
            </router-link>
          </div>
        </div>
        <!-- End Col -->

        <div class="col-sm-12 col-md-12 col-lg-6">
          <div
            id="carouselExampleControls"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div
                v-for="(slider, index) in 4"
                :key="slider"
                class="carousel-item"
                :class="{ active: index == 0 }"
              >
                <img
                  :src="`/img/slider${index + 1}.jpeg`"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
            </div>
            <button
              class="carousel-control-prev p-3"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span
                class="btn-primary-dark rounded-circle p-2"
                aria-hidden="true"
                ><svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.6 4L6.6 12L14.6 20H10.6L3.3 12.7C2.9 12.3 2.9 11.7 3.3 11.3L10.6 4H14.6Z"
                    fill="#ffff"
                  ></path>
                  <path
                    opacity="0.3"
                    d="M21.6 4L13.6 12L21.6 20H17.6L10.3 12.7C9.9 12.3 9.9 11.7 10.3 11.3L17.6 4H21.6Z"
                    fill="#ffff"
                  ></path></svg
              ></span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <span
                class="btn-primary-dark rounded-circle p-2"
                aria-hidden="true"
                ><svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 4L18 12L10 20H14L21.3 12.7C21.7 12.3 21.7 11.7 21.3 11.3L14 4H10Z"
                    fill="#ffff"
                  ></path>
                  <path
                    opacity="0.3"
                    d="M3 4L11 12L3 20H7L14.3 12.7C14.7 12.3 14.7 11.7 14.3 11.3L7 4H3Z"
                    fill="#ffff"
                  ></path></svg
              ></span>
            </button>
          </div>
        </div>
        <!-- End Col -->
      </div>
      <!-- End Row -->

      <!-- stadistics -->
      <!-- <div class="row p-5 justify-content-center text-center"> -->
      <!-- <div class="col-md-4 col-lg-4 col-sm-12 mb-3 mb-sm-0">
          <span class="h1 text-white fs-2 fw-bold">1k+</span>
          <p class="lead fs-4 fw-600 text-white-gray">Students.</p>
        </div> -->
      <!-- End Col -->

      <!-- <div class="col-md-4 col-lg-4 col-sm-12 mb-3 mb-sm-0">
          <span class="h1 text-white fs-2 fw-bold">20</span>
          <p class="lead fs-4 fw-600 text-white-gray">majors</p>
        </div> -->
      <!-- End Col -->

      <!-- <div class="col-md-4 col-lg-4 col-sm-12">
          <span class="h1 text-white fs-2 fw-bold">400</span>
          <p class="lead fs-4 fw-600 text-white-gray">researchs</p>
        </div> -->
      <!-- End Col -->
      <!-- </div> -->
      <!-- End Row -->
    </div>
  </div>
  <!-- End Hero -->
</template>

<script>
/* typed js */
import Typed from "typed.js";

export default {
  name: "heroSection",
  data() {
    return {
      typed: null,
    };
  },
  mounted() {
    this.typed = new Typed("#typed", {
      strings: ["Center"],
      typeSpeed: 100,
      backSpeed: 50,
    });
  },
  unmounted() {
    this.typed.destroy();
  },
};
</script>
