<template>
  <RRSheader />
  <hero-section />
  <logIn-modal />
  <signUp-Modal />
  <how-it-work />
  <RRSfooter />
</template>

<script>
import RRSheader from "@/components/RRSheader.vue";
import HeroSection from "@/components/staticPages/heroSection.vue";
import signUpModal from "@/components/signUpModal.vue";
import logInModal from "@/components/logInModal.vue";
import HowItWork from "@/components/staticPages/howItWork.vue";
import RRSfooter from "@/components/staticPages/RRSfooter.vue";
import store from "@/store/index.js";

export default {
  name: "Home",
  components: {
    HeroSection,
    signUpModal,
    logInModal,
    HowItWork,
    RRSfooter,
    RRSheader,
  },
  beforeRouteEnter(to, from, next) {
    if (store.state.authenticated) {
      next({ name: "main" });
    } else {
      next();
    }
  },
};
</script>
